import { useCallback } from "react";
import DefaultAvatar from "assets/images/default-avatar.svg";
import FileResponseResource from "common/resources/File/FileResponseResource";
import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";

type Contributor = {
	avatar?: Pick<FileResponseResource, "url">;
	name: string;
};

type IProps = {
	contributor: Contributor;
};

export default function ContributorBadge(props: IProps) {
	const getAvatarUrl = useCallback(() => {
		return props.contributor.avatar?.url ?? DefaultAvatar;
	}, [props.contributor.avatar]);

	return (
		<div className={classes["root"]}>
			<img src={getAvatarUrl()} alt="Avatar" className={classes["avatar"]} />
			<Typography type="p" weight="bold" size="small">
				{props.contributor.name}
			</Typography>
		</div>
	);
}
