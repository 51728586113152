import CBPartners from "assets/images/featuredProjects/malgremoi/cb-partners.jpg";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import ContributorBadge from "components/pages/FeaturedProjects/components/ContributorBadge";
import classes from "./classes.module.scss";

export default function ThisProjectLeader() {
	return (
		<div className={classes["root"]}>
			<Typography type="p" weight="medium" size="small">
				{I18n.trslt(I18n.asset.pages.featuredProjects.malgre_moi.team.project_leader.role)}
			</Typography>
			<ContributorBadge
				contributor={{
					avatar: { url: CBPartners },
					name: I18n.trslt(I18n.asset.pages.featuredProjects.malgre_moi.team.project_leader.name),
				}}
			/>
		</div>
	);
}
