import SubscribeDesignOne from "assets/images/subscribe-design-1.svg";
import SubscribeDesignTwo from "assets/images/subscribe-design-2.svg";
import SubscribeDesignThree from "assets/images/subscribe-design-3.svg";
import SubscribeDesignFour from "assets/images/subscribe-design-4.svg";
import SubscribeDesignFive from "assets/images/subscribe-design-5.svg";
import SubscribeDesignSix from "assets/images/subscribe-design-6.svg";
import SubscribeDesignSeven from "assets/images/subscribe-design-7.svg";
import SubscribeDesignEight from "assets/images/subscribe-design-8.svg";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";

export default function Abilities() {
	return (
		<div className={classes["root"]}>
			<Typography type="h2" weight="bold">
				{I18n.trslt(I18n.asset.pages.subscribe.abilities_title)}
			</Typography>

			<div className={classes["design-container"]}>
				<div className={classes["design"]}>
					<img src={SubscribeDesignOne} alt="" className={classes["design-image"]} />

					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.deposit)}
					</Typography>
				</div>

				<div className={classes["design"]}>
					<img src={SubscribeDesignTwo} alt="" className={classes["design-image"]} />

					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.federate)}
					</Typography>
				</div>

				<div className={classes["design"]}>
					<img src={SubscribeDesignThree} alt="" className={classes["design-image"]} />

					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.contribute)}
					</Typography>
				</div>

				<div className={classes["design"]}>
					<img src={SubscribeDesignFour} alt="" className={classes["design-image"]} />

					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.use_data)}
					</Typography>
				</div>

				<div className={classes["design"]}>
					<img src={SubscribeDesignFive} alt="" className={classes["design-image"]} />

					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.deposit_content)}
					</Typography>
				</div>

				<div className={classes["design"]}>
					<img src={SubscribeDesignSix} alt="" className={classes["design-image"]} />

					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.offer)}
					</Typography>
				</div>

				<div className={classes["design"]}>
					<img src={SubscribeDesignSeven} alt="" className={classes["design-image"]} />

					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.drive)}
					</Typography>
				</div>

				<div className={classes["design"]}>
					<img src={SubscribeDesignEight} alt="" className={classes["design-image"]} />

					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.shape)}
					</Typography>
				</div>
			</div>
		</div>
	);
}
