import { match } from "ts-pattern";
import DefaultAvatar from "assets/images/default-avatar.svg";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import DimProjectLeader from "components/pages/FeaturedProjects/Dim/ProjectDetails/ThisProjectLeader";
import MalgreMoiProjectLeader from "components/pages/FeaturedProjects/MalgreMoi/ProjectDetails/ThisProjectLeader";
import classes from "./classes.module.scss";

type IProps = {
	project: ProjectResponseResource;
};

export default function ProjectLeaderBadge(props: IProps) {
	return (
		<div className={classes["root"]}>
			{match(props.project.componentName)
				.with("Dim", () => <DimProjectLeader />)
				.with("MalgreMoi", () => <MalgreMoiProjectLeader />)
				.otherwise(() => (
					<DefaultProjectLeaderBadge {...props} />
				))}
		</div>
	);
}

function DefaultProjectLeaderBadge(props: IProps) {
	const avatar = props.project.author.avatar?.url ?? DefaultAvatar;
	const author = `${props.project.author.firstName} ${props.project.author.lastName}`;

	return (
		<>
			<Typography type="p" size="medium" weight="medium">
				{I18n.trslt(I18n.asset.component.featured_project.project_leader)}
			</Typography>

			<div className={classes["avatar"]}>
				<img src={avatar} alt="Avatar" className={classes["avatar"]} />
			</div>

			<Typography type="p" size="small" weight="bold" fontFamily="secondary">
				{author}
			</Typography>
		</>
	);
}
