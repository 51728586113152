import { XMarkIcon } from "@heroicons/react/20/solid";
import ComingSoonContent from "./ComingSoonContent";
import classes from "./classes.module.scss";

type IProps = {
	onClose: () => void;
};

export default function ComingSoon(props: IProps) {
	return (
		<div className={classes["root"]}>
			<ComingSoonContent />
			<div className={classes["close-container"]}>
				<XMarkIcon className={classes["close-icon"]} onClick={props.onClose} color="white" />
			</div>
		</div>
	);
}
