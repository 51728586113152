import { EProjectFormat } from "common/enums/ProjectFormat/ProjectFormat";
import { EProjectPurpose } from "common/enums/ProjectPurpose";
import { EProjectReason } from "common/enums/ProjectReason";
import { EProjectTheme } from "common/enums/ProjectTheme/ProjectTheme";
import { EValidationError } from "common/enums/Validation/ValidationErrors";

const EProjectReasonImplementation: Record<EProjectReason, string> = {
	PROTECT: "Je dépose mon projet simplement pour le protéger",
	PROTECT_AND_DEVELOPMENT_AID: "Je dépose mon projet pour le protéger et le soumettre à l'aide au développement",
};

const EProjectPurposeImplementation: Record<EProjectPurpose, string> = {
	FUNDING_APPLICATION: "Le soumettre au comité de sélection de LaDCF (La Diversité du Cinéma Français) afin de prétendre à une aide au développement",
	AUTHENTICITY_PROTECTION: "Pour le protéger et obtenir un certificat d’authenticité",
	PITCH_TO_PROFESSIONALS: "Obtenir une chance de le pitcher à des professionnels, grâce à Neeedco.",
};

const EProjectFormatImplementation: Record<EProjectFormat, string> = {
	court_metrage: "Court-métrage",
	documentaire: "Documentaire",
	long_metrage: "Long-métrage",
	serie: "Série",
};

const EProjectThemeImplementation: Record<EProjectTheme, string> = {
	comedie: "Comédie",
	comedie_romantique: "Comédie romantique",
	comedie_dramatique: "Comédie dramatique",
	biopic: "Biopic",
	thriller: "Thriller",
	film_daction: "Film d'action",
	film_policier: "Film policier",
	film_dhorreur: "Film d'horreur",
	film_historique: "Film historique",
	science_fiction: "Science fiction",
	film_danimation: "Film d'animation",
	comedie_musicale: "Comédie musicale",
	dramedie: "Dramédie",
	comedie_absurde: "Comédie absurde",
	thriller_psychologique: "Thriller psychologique",
};

const EValidationErrorImplementation: Record<EValidationError, string> = {
	isArray: "Vous devez sélectionner au moins une valeur.",
	isBoolean: "Ce champ est obligatoire.",
	isEnum: "Ce champ est obligatoire.",
	isInt: "Assurez-vous d'entrer un nombre entier, sans décimales.",
	isNotEmpty: "Ce champ est obligatoire.",
	isNumber: "Ce champ est obligatoire. La valeur doit être un nombre.",
	isObject: "Ce champ est obligatoire.",
	isString: "Ce champ est obligatoire.",
	isUrl: "Veuillez entrer une URL valide.",
	max: "Le nombre doit être inférieur ou égal à {{var1}}.",
	min: "Le nombre doit être supérieur ou égal à {{var1}}.",
	badCredentials: "Identifiants incorrects. Veuillez réessayer.",
	emailNotExists: "L'email n'existe pas.",
	isEmail: "Veuillez entrer une adresse e-mail valide.",
	isNumberString: "Ce champ est obligatoire. La valeur doit être numérique.",
	isPositive: "Le nombre doit être positif.",
	maxLength: "La longueur du texte ne doit pas dépasser {{var1}} caractères.",
	passwordNotMatch: "Les mots de passe ne correspondent pas",
	isStrongPassword: "Le mot de passe doit être plus complexe pour des raisons de sécurité.",
	pinCodeNotValid: "Le code PIN n'est pas correct.",
	uniqueEmail: "Veuillez utiliser une adresse e-mail différente, celle-ci est déjà utilisée.",
	wrongPassword: "Le mot de passe que vous avez saisi est incorrect.",
	arrayNotEmpty: "Ce champ est obligatoire.",
	match: "Les valeurs ne correspondent pas.",
	minLength: "La longueur du texte doit être d'au moins {{var1}} caractères.",
	emailNotFound: "L'adresse e-mail que vous avez fournie n'est associée à aucun compte.",
	fileNotExists: "Le fichier n'existe pas.",
	emailCaseSensitive: "L'adresse e-mail doit être en minuscules.",
	anchorNotExists: "L'ancrage n'existe pas.",
	choiceNotExists: "Le choix n'existe pas.",
	mustBeInThePast: "La date doit être dans le passé.",
	uniqueSha256: "Il n'est pas possible de soumettre deux fois le même fichier.",
	invalidPhoneNumber: "Seuls les formats français ou international sont acceptés.",
	invalidLinkedInUrl: "Veuillez entrer une URL LinkedIn valide.",
	isDate: "Veuillez entrer une date valide.",
};

const trads = {
	common: {
		cancel: "Annuler",
		logout: "Déconnexion",
		search: "Rechercher",
		yes: "Oui",
		no: "Non",
		save: "Enregistrer",
		back: "Retour",
		delete: "Supprimer",
		add: "Ajouter",
		next: "Suivant",
		crew: "L'équipe du film",
	},
	pages: {
		admin_dashboard: {
			page_title: "Projets déposés",
			title: "Projets déposés",
			filters: {
				all_projects: "Tous les projets",
				accepted: "Retenu",
				refused: "Non retenu",
				studying: "En cours d'étude",
				archived: "Archives",
			},
			table: {
				need_to_be_studied: {
					yes: "Oui",
					no: "Non",
				},
				headers: {
					project_name: "Nom du projet",
					author: "Auteur",
					status: "Statut",
					date: "Date",
				},
				buttons: {
					cv: "Télécharger le CV",
					scenario: "Télécharger le scénario",
					data: "Informations sur le projet",
					status: "Statut",
					archive: "Archiver",
					restore: "Restaurer",
					anchor: "Ancrer",
				},
				no_cv: "Aucun CV",
				no_scenario: "Aucun scénario",
			},
			drawer: {
				poster: {
					regenerate: "Regénérer une affiche",
					pending: "L'affiche est en cours de génération",
					failed: "L’image n’a pas pu être générée par IA. Cela peut être dû à un lien dans votre pitch ou à l’utilisation de mots pouvant être inappropriés.",
				},
				author: "Auteur",
				infos: {
					title: "Informations",
					publication_date: "Publication :",
					genre: "Genre :",
					format: "Format :",
					reason: "Raison :",
					accepted_nota_bene: "NB : Votre projet est retenu pour être soumis au Comité de Sélection de LaDCF.",
				},
				buttons: {
					cv: "Télécharger le CV",
					scenario: "Télécharger le scénario",
					archive: "Archiver",
					restore: "Restaurer",
				},
			},
		},
		project: {
			page_title: "Page du projet",
			publication: "Publication: ",
			genre: "Genre: ",
			format: "Format: ",
			buttons: {
				readMore: "Lire plus",
				readLess: "Lire moins",
			},
			follow_us: "Suivez nous sur :",
		},
		featuredProjects: {
			common: {
				casting: {
					coming_soon: {
						title: "Bientôt, aidez-nous à imaginer le casting du film",
					},
				},
				vote: {
					title: "Vote en cours",
				},
				previous_vote: {
					title: "Vote passé",
				},
			},
			grand_saut: {
				page_title: "Le grand saut",
				header: {
					properties: {
						publication: {
							key: "Publication :",
							value: "le 10 mai 2024",
						},
						genre: {
							key: "Genre :",
							value: "Comédie romantique",
						},
						format: {
							key: "Format :",
							value: "Long-métrage",
						},
					},
				},
				production_stage: {
					label: "Stade de production :",
					value: "Écriture de la V1",
				},
				project_needs: {
					label: "En recherche :",
					value: "Rôles principaux, financement",
				},
				content: {
					title: "Le grand saut",
					subtitle: "L’amour rend aveugle, le suicide va leur rendre la vue",
					description:
						"Le Grand Saut raconte l’histoire d’Antoine et Lucie, deux âmes brisées que tout oppose, réunies par un étrange point commun : leur désir d’en finir. Leur rencontre dans une ambulance, après avoir chacun échoué leur tentative de suicide, va faire basculer leur vie ou leur mort….  Entre ascensions nocturnes, confrontations explosives et dialogues empreints d’ironie mordante, leur cheminement commun les entraîne dans une évasion surréaliste. Au fil de leurs mésaventures, ils se dévoilent l’un à l’autre et affrontent leurs blessures profondes. À travers leur quête désespérée, ce duo dysfonctionnel met en lumière la force de l’instinct de survie et l’étrange pouvoir de la connexion humaine, même dans les moments les plus sombres.",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "legrandsaut@clapaction.com",
						},
					},
				},
				tabs: {
					actions: "Actions",
					forum: "Forum",
					casting: "Casting",
					media: "Média",
					team: "Équipe",
				},
				action: {
					title: "Action à venir",
					description: "Candidatez pour devenir l’un des trois premiers lecteurs de la V1 (Première version du scénario) / envoyez nous un mail.",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "legrandsaut@clapaction.com",
						},
					},
				},
				forum: {
					title: "Forum du projet",
					anwsers: "{{n}} réponses",
					single_anwser: "{{n}} réponse",
				},
				casting: {
					title: "Devenez une voix dans le casting",
					description:
						"Dans notre espace forum, la directrice de casting et le réalisateur vous invitent à contribuer en suggérant vos choix d’acteurs pour les rôles décrits dans leurs annonces. À vous de jouer !",
					button: "Voir le forum",
				},
				media: {
					title: "Photos & Vidéos",
				},
				team: {
					title: "Équipe",
					project_leader: {
						role: "Porteur de projet :",
						name: "Anthony Granier",
					},
					referent_agent: {
						role: "Agent référent :",
						name: "Isabelle Frère / If Only",
					},
					authors: {
						role: "Auteur(e)(s) :",
						names: ["Anthony Granier", "Sophie Garric"],
					},
				},
			},
			malgre_moi: {
				page_title: "Malgré moi",
				header: {
					properties: {
						publication: {
							key: "Publication :",
							value: "le 10 mai 2024",
						},
						genre: {
							key: "Genre :",
							value: "Thriller psychologique",
						},
						format: {
							key: "Format :",
							value: "Long-métrage",
						},
					},
				},
				production_stage: {
					label: "Stade de production :",
					value: "Actuellement en casting",
				},
				project_needs: {
					label: "En recherche :",
					value: "Rôles secondaires, financements",
				},
				content: {
					title: "Malgré moi",
					subtitle: "Le pouvoir du deni",
					description:
						"Théo, un homme marqué par un incendie qui a bouleversé son enfance, voit son monde s’effondrer lorsque sa femme disparaît sans explication. Entre séances chez un psychiatre et flashbacks douloureux, il tente de comprendre ce qui a provoqué cet abandon soudain.",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "malgremoi@clapaction.com",
						},
					},
				},
				creative_source: {
					description: "D'après le roman  « Malgré Nous » de Claire Norton.",
				},
				tabs: {
					actions: "Actions",
					forum: "Forum",
					casting: "Casting",
					media: "Média",
					team: "Équipe",
				},
				action: {
					title: "Action à venir",
					description:
						"Vous avez occupé le poste de chef déco sur un long-métrage déjà diffusé ? Si Malgré Moi vous intrigue, contactez-nous ! Nous enverrons le scénario aux premiers candidats afin de découvrir, après lecture, votre vision artistique (moodboard, propositions, etc.) / envoyez nous un mail.",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "malgremoi@clapaction.com",
						},
					},
				},
				forum: {
					title: "Forum du projet",
					anwsers: "{{n}} réponses",
					single_anwser: "{{n}} réponse",
				},
				media: {
					title: "Photos & Vidéos",
				},
				team: {
					title: "Équipe",
					project_leader: {
						role: "Porteur de projet :",
						name: "CB Partners",
					},
					referent_agent: {
						role: "Agent référent :",
						name: "Ghislaine Gracieux",
					},
					authors: {
						role: "Auteur(e)(s) :",
						names: ["Laurent Bouhnik"],
					},
					directors: {
						role: "Réalisateur(rice) :",
						names: ["Laurent Bouhnik"],
					},
					casting_directors: {
						role: "Directeur de casting :",
						names: ["Okinawa Guerard  / Encore un casting"],
					},
					producers: {
						role: "Producteur(s) :",
						names: ["Carlos Bedran", "Sarah Lelouch"],
					},
				},
			},
			kdanse: {
				page_title: "KDanse",
				header: {
					properties: {
						publication: {
							key: "Publication :",
							value: "le 10 mai 2024",
						},
						genre: {
							key: "Genre :",
							value: "Dramedie",
						},
						format: {
							key: "Format :",
							value: "Long-métrage",
						},
					},
				},
				production_stage: {
					label: "Stade de production :",
					value: "Écriture du séquenceur",
				},
				project_needs: {
					label: "En recherche :",
					value: "Co-autrice, rôles principaux, financement",
				},
				content: {
					title: "En K-Danse",
					subtitle: "Les champs de la résilience",
					description:
						"Dans les montagnes paisibles de Haute-Savoie, une famille lutte contre la maladie, les dettes et le poids du silence. David, le fils cadet, agriculteur dévoué, voit son monde bouleversé par le retour inattendu d'Émilie, sa sœur, devenue Emile, transgenre et passionnée de K-Pop…. Avec une idée aussi audacieuse qu’absurde, Émile propose de participer à un concours de K-Pop pour sauver la ferme familiale. Entre confrontations et réconciliations, cette aventure inattendue dévoile leurs blessures et leur résilience. Mais le temps joue contre eux : sauront-ils triompher à ce concours et à de leurs propres conflits ?",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "enkdanse@clapaction.com",
						},
					},
				},
				tabs: {
					actions: "Actions",
					forum: "Forum",
					casting: "Casting",
					media: "Média",
					team: "Équipe",
				},
				action: {
					title: "Action à venir",
					description:
						"Nous proposer vos groupes de K-Pop préférés afin qu’on les sollicite pour nous accompagner dans la composition musicale de notre film. Si vous avez d’ores et déjà des idées, n’hésitez pas à nous envoyer un mail.",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "enkdanse@clapaction.com",
						},
					},
				},
				forum: {
					title: "Forum du projet",
					anwsers: "{{n}} réponses",
					single_anwser: "{{n}} réponse",
				},
				media: {
					title: "Photos & Vidéos",
				},
				team: {
					title: "Équipe",
					project_leader: {
						role: "Porteur de projet :",
						name: "Philippe Gray",
					},
					referent_agent: {
						role: "Agent référent :",
						name: "Fred Malek / Agence Caractère",
					},
					authors: {
						role: "Auteur(e)(s) :",
						names: ["Philippe Gray"],
					},
					directors: {
						role: "Réalisateur(rice) :",
						names: ["Philippe Gray"],
					},
				},
			},
			friends: {
				page_title: "Celle qui n'avait pas vu Friends",
				header: {
					properties: {
						publication: {
							key: "Publication :",
							value: "le 10 mai 2024",
						},
						genre: {
							key: "Genre :",
							value: "Comédie absurde",
						},
						format: {
							key: "Format :",
							value: "Long-métrage",
						},
					},
				},
				production_stage: {
					label: "Stade de production :",
					value: "Film en financement",
				},
				project_needs: {
					label: "En recherche :",
					value: "Complément de financement",
				},
				content: {
					title: "Celle qui n'avait pas vu Friends",
					subtitle: "Quand tu te fais quitter le jour de ton mariage...",
					description:
						"Greg, 34 ans, retrouve sa future femme Rachel Karlson, 30 ans, quelques minutes avant la cérémonie. Elle est si belle… Il a envie de dire « oh-mon-Dieu!!!! » en référence à la série <i>Friends</i>. Rachel ne comprends pas sa blague... Et pour cause, elle n'a jamais vraiment regardé cette série, elle n’a jamais aimé. Greg est sous le choc, il ne veut plus se marier.",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "cellequi@clapaction.com",
						},
					},
				},
				tabs: {
					actions: "Actions",
					forum: "Forum",
					casting: "Casting",
					media: "Média",
					team: "Équipe",
				},
				action: {
					title: "Action à venir",
					description:
						"Candidater pour l’un des derniers rôles principaux du film en nous envoyant vos essais. Nous vous communiquerons bientôt les infos pour y participer.",
				},
				forum: {
					title: "Forum du projet",
					anwsers: "{{n}} réponses",
					single_anwser: "{{n}} réponse",
				},
				casting: {
					title: "Casting confirmé",
					male: "Interprété par",
					female: "Interprétée par",
				},
				media: {
					title: "Photos & Vidéos",
				},
				team: {
					title: "Équipe",
					project_leader: {
						role: "Porteur de projet :",
						name: "Charlotte Gabris",
					},
					referent_agent: {
						role: "Agent référent :",
						name: "Alexandra Schamis / AS TALENTS",
					},
					authors: {
						role: "Auteur(e)(s) :",
						names: ["Charlotte Gabris"],
					},
					directors: {
						role: "Réalisateur(rice) :",
						names: ["Charlotte Gabris"],
					},
				},
			},
			lattente: {
				page_title: "L’attente",
				header: {
					properties: {
						publication: {
							key: "Publication :",
							value: "le 10 décembre 2024",
						},
						genre: {
							key: "Genre :",
							value: "Dramédie",
						},
						format: {
							key: "Format :",
							value: "Long-métrage",
						},
					},
				},
				production_stage: {
					label: "Stade de production :",
					value: "Début de l'écriture",
				},
				project_needs: {
					label: "En recherche :",
					value: "Co-autrice, réalisateur(rice), co-producteur, rôles principaux, financement",
				},
				content: {
					title: "L’attente",
					subtitle: "Dans l'attente, se cachent les réponses qu'on fuit",
					description:
						"Dans un monde où tout va trop vite, Serge Lassart, ancien ouvrier devenu visionnaire, imagine l'impensable : une immense salle d'attente universelle, un lieu où chacun est invité à faire une pause, à ralentir, et à réfléchir sur le sens de sa vie.<br /><br />De cet espace naît un mouvement collectif, bousculant les habitudes et révélant les destins croisés de personnages aussi différents qu’attachants.<br /><br />Des vies en suspens, des destins qui s’entrelacent : le narrateur, un homme mélancolique en quête de sens : les sœurs jumelles, mystérieuses voyageuses en quête de leurs origines ; Sandrine, un amour de jeunesse ; et April, une figure cynique de la société moderne, prisonnière d’un monde de performance et d’apparences.<br /><br />Avec une mise en scène chorale, L'Attente interroge notre rapport au temps, à la solitude et à la nécessité de se reconnecter à l’essentiel.<br /><br />À mesure que les trajectoires de ces personnages s’entrelacent, chaque attente révèle une vérité essentielle. Une tragédie bouleversante où le destin redistribue les cartes, questionnant notre besoin de reconnaissance et le véritable sens du bonheur.",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "lattente@clapaction.com",
						},
					},
				},
				creative_source: {
					description: "D'après le roman  « L’ATTENTE » de Sébastien Praicheux.",
				},
				tabs: {
					actions: "Actions",
					forum: "Forum",
					casting: "Casting",
					media: "Média",
					team: "Équipe",
				},
				action: {
					title: "Action",
					description: "Si vous avez déjà écrit un scénario d’un projet film diffusé, candidatez pour collaborer à l’écriture / envoyez nous un mail.",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "lattente@clapaction.com",
						},
					},
				},
				forum: {
					title: "Forum du projet",
					anwsers: "{{n}} réponses",
					single_anwser: "{{n}} réponse",
				},
				media: {
					title: "Photos & Vidéos",
				},
				team: {
					title: "Équipe",
					project_leader: {
						role: "Porteur de projet :",
						name: "Sébastien Praicheux",
					},
					authors: {
						role: "Auteur(e)(s) :",
						names: ["Sébastien Praicheux"],
					},
				},
			},
			dim: {
				page_title: "DIM, L’histoire Vraie",
				header: {
					properties: {
						publication: {
							key: "Publication :",
							value: "le 5 février 2025",
						},
						genre: {
							key: "Genre :",
							value: "Biopic",
						},
						format: {
							key: "Format :",
							value: "Long-métrage",
						},
					},
				},
				production_stage: {
					label: "Stade de production :",
					value: "Synopsis",
				},
				project_needs: {
					label: "En recherche :",
					value: "Recherche du réalisateur et de co-producteurs",
				},
				content: {
					title: "DIM, L’histoire Vraie",
					subtitle: "L’homme au bas Nylon",
					description:
						"Entre biopic romantique et hommage vibrant, DIM - L’Histoire Vraie raconte la vie de Bernard Giberstein, sa face sombre et celle plus solaire : un visionnaire qui a révolutionné la place des femmes dans la société. Porté par son amour pour Sarah, sa muse et épouse, il transforme un simple collant en symbole de liberté et d’émancipation. Rescapé de la guerre, il offre aux femmes bien plus que des vêtements : la clé de leur autonomie, à l’image de cette première leçon de conduite qu’il a donnée à Sarah, déclarant (lui tendant les clés de sa voiture) : « La liberté, c’est avant tout savoir conduire. »…",
					buttons: {
						contact: {
							label: "Contacter l'équipe",
							email: "dimlefilm@clapaction.com",
						},
					},
				},
				tabs: {
					actions: "Actions",
					forum: "Forum",
					casting: "Casting",
					media: "Média",
					team: "Équipe",
				},
				forum: {
					title: "Forum du projet",
					anwsers: "{{n}} réponses",
					single_anwser: "{{n}} réponse",
				},
				media: {
					title: "Photos & Vidéos",
				},
				team: {
					title: "Équipe",
					project_leader: {
						role: "Porteur de projet :",
						name: "Daniel Giberstein",
					},
					authors: {
						role: "Auteur(e)(s) :",
						names: ["Daniel Giberstein"],
					},
					producers: {
						role: "Producteur(s) :",
						names: ["WATCH’US PROCUCTIONS"],
					},
				},
			},
		},
		terms_of_use: {
			page_title: "Conditions d'utilisation",
			title: "Conditions d'utilisation",
			subtitle: "Politique de confidentialité",
			date: "Date de prise d'effet : 27/06/2024",
			watch_us: "WATCH'US (« nous », « notre », « nos ») exploite le site web https://www.techcannes.com/ et https://www.clapaction.com/",
			collect:
				"Cette page vous explique nos politiques en matière de collecte, d'utilisation et de communication des données à caractère personnel lorsque vous utilisez notre Site ainsi que les choix qui s'offrent à vous en ce qui concerne ces données.",
			use_data:
				"Nous utilisons vos données pour fournir et améliorer le Site. En utilisant le Site, vous consentez à la collecte et à l'utilisation d'informations conformément à la présente politique.",
			definitions: {
				title: "Définitions",
				site: "Site : Par Site on entend le site web https://www.techcannes.com/ et https://www.clapaction.com/ exploité par la société Watch'Us",
				items: [
					"Données à caractère personnel : Données à Caractère Personnel désigne des données concernant un individu vivant qui peut être identifié à partir de ces données (ou à partir de ces données et d'autres informations en notre possession ou susceptibles d'entrer en notre possession).",
					"Données d'Utilisation : Les Données d'Utilisation sont recueillies automatiquement et sont générées soit par l'utilisation du Site, soit par l'infrastructure du Site proprement dite (par exemple, durée de consultation d'une page).",
					"Cookies : Les cookies sont de petits fichiers enregistrés sur votre dispositif (ordinateur ou dispositif mobile).",
					"Responsable du Traitement : Responsable du Traitement désigne la personne physique ou morale (seule, ou conjointement ou en commun avec d'autres personnes) qui détermine les finalités et les modalités selon lesquelles toutes les données personnelles sont traitées ou doivent l'être. Aux fins de la présente Politique de Confidentialité, nous sommes un Responsable du Traitement de vos données à caractère personnel.",
					"Sous-Traitants (ou Prestataires de Services) : Le terme Sous-Traitant (ou Prestataire de Service) désigne toute personne physique ou morale qui traite les données au nom du Responsable du Traitement. Il se peut que nous fassions appel aux services de plusieurs Prestataires de Services afin de traiter vos données plus efficacement.",
					"Personne Concernée (ou Utilisateur) : Par Personne Concernée, on entend toute personne vivante qui utilise notre Site et est le sujet de Données à Caractère Personnel.",
				],
			},
			data_collection_and_use: {
				title: "Collecte et utilisation des données",
				subtitle: "Nous recueillons plusieurs types de données à différentes fins en vue de vous fournir notre Site et de l'améliorer.",
				types_of_data_collected: {
					title: "Types de données recueillies",
					personal_data: {
						title: "Données à Caractère Personnel",
						description:
							"Lorsque vous utilisez notre Site, il est possible que nous vous demandions de nous fournir certaines données personnelles nominatives qui peuvent servir à vous contacter ou à vous identifier (« Données à Caractère Personnel »). Les données personnelles nominatives peuvent comprendre, mais de manière non limitative :",
						items: ["Adresse e-mail", "Prénom et nom de famille", "Cookies et Données d'Utilisation"],
						use: "Nous utilisons vos Données à Caractère Personnel pour vous envoyer une réponse à votre demande effectuée via le formulaire de contact de notre site web. Nous nous engageons à ne pas vous envoyer de promotions et de démarchage commercial si vous ne nous donnez pas votre accord.",
					},
					usage_data: {
						title: "Données d'Utilisation",
						description:
							"Nous pouvons également recueillir des informations relatives au mode d'accès et d'utilisation du Site (« Données d'Utilisation »). Ces Données d'Utilisation peuvent comprendre des informations telles que l'adresse de protocole Internet (c.-à-d. l'adresse IP) de votre ordinateur, le type de navigateur, la version du navigateur, les pages de notre Site que vous consultez, la date et l'heure de votre visite, le temps passé sur ces pages, les identifiants uniques de dispositifs ainsi que d'autres données de diagnostic.",
					},
					tracking_cookies_data: {
						title: "Suivi et données de cookies",
						description:
							"Nous avons recours à des cookies et à d'autres technologies de suivi similaires pour effectuer un suivi des activités effectuées dans notre Site et nous conservons certaines informations.",
						definition:
							"Les cookies sont des fichiers à faible volume de données pouvant comporter un identifiant unique anonyme. Les cookies sont envoyés à votre navigateur depuis un site web et sont stockés sur votre dispositif.",
						consent: "Vous pouvez accepter ou refuser les Cookies directement via le panneau de gestion des Cookies en bas à droite du site web.",
					},
				},
				use_of_data: {
					title: "Utilisation des données",
					description: "La DCF utilise les données recueillies à des fins diverses :",
					items: [
						"Pour fournir et assurer notre Site",
						"Pour assurer assistance client",
						"Pour recueillir des données précieuses ou d'analyses qui nous permettront d'améliorer notre Site",
						"Pour contrôler l'utilisation de notre Site",
						"Pour détecter, prévenir et régler les problèmes techniques",
						"Pour répondre à toutes questions ou demandes de votre part via le formulaire de contact",
					],
				},
			},
			legal_basis: {
				title: "Fondement juridique du traitement des données à caractère personnel en vertu du Règlement Général relatif à la Protection des Données (RGPD)",
				description:
					"Si vous résidez dans l'Espace économique européen (EEE), le fondement juridique de La DCF en ce qui concerne la collecte et l'utilisation des données personnelles décrites dans la présente Politique de Confidentialité dépend des Données à Caractère Personnel que nous recueillons et du contexte précis dans lequel nous les recueillons.",
				legal_basis: [
					"Parce que vous nous avez autorisés à le faire",
					"Parce que nous avons un intérêt légitime à effectuer ce traitement et que vos droits ne priment pas sur cet intérêt légitime",
					"Pour respecter la loi",
				],
			},
			data_retention: {
				title: "Conservation des données",
				description:
					"Watch'Us ne conservera vos Données à Caractère Personnel que tant que cela sera nécessaire aux fins stipulées dans la présente Politique de Confidentialité. Nous conserverons et utiliserons vos Données à Caractère Personnel dans la mesure où cela sera nécessaire pour que nous puissions nous acquitter de nos obligations légales (par exemple pour respecter la législation en vigueur), résoudre des différends et appliquer nos accords et nos politiques. La durée légale de conservation de vos Données à Caractère Personnel est de 3 ans maximum.",
				usage_data_retention:
					"Watch'Us conservera également les Données d'Utilisation à des fins d'analyses internes. Les Données d'Utilisation sont généralement conservées pour une période plus courte sauf lorsque ces données sont utilisées pour renforcer la sécurité ou pour améliorer les fonctionnalités de notre Site ou si nous sommes légalement tenus de conserver ces données plus longtemps. La durée légale de conservation des Données d'Utilisation est de 13 mois maximum.",
			},
			data_transfer: {
				title: "Transfert des données",
				description:
					"Les informations vous concernant, notamment vos Données à Caractère Personnel, peuvent être transférées de votre région, province, pays ou autre division territoriale vers des ordinateurs – et stockées sur ces derniers – situés à un endroit où la législation relative à la protection des données diffère de celle du territoire où vous résidez.",
				international_transfer:
					"Si vous résidez hors de France et que vous choisissez de nous communiquer des informations, sachez que nous transférons les données, y compris les Données à Caractère Personnel, vers la France et que nous les y traitons. En acceptant la présente Politique de Confidentialité puis en soumettant ces informations, vous consentez à ce transfert.",
				security_measures:
					"Watch'Us prendra toutes les mesures raisonnablement nécessaires pour faire en sorte que vos données soient traitées de manière sécurisée et conformément à la présente Politique de Confidentialité, et vos Données à Caractère Personnel ne seront transférées vers aucune organisation ni aucun pays à moins que des contrôles adéquats ne soient en place, notamment en ce qui concerne la sécurité de vos données et d'autres données personnelles.",
			},
			data_disclosure: {
				title: "Communication de données",
				law_enforcement: {
					title: "Communication de données aux forces de l'ordre",
					description:
						"Dans certaines circonstances, Watch'Us peut être tenue de communiquer vos Données à Caractère Personnel si la loi l'exige ou en réponse à des demandes valides émanant de pouvoirs publics (par ex. un tribunal ou un organisme gouvernemental).",
				},
				legal_requirements: {
					title: "Exigences légales",
					description: "Watch'Us peut communiquer vos Données à Caractère Personnel si elle estime de bonne foi que cela est nécessaire pour :",
					items: [
						"S'acquitter d'une obligation légale",
						"Protéger et défendre les droits ou les biens de la DCF",
						"Prévenir d'éventuels actes répréhensibles ou enquêter sur de tels actes dans le cadre du Site",
						"Assurer la sécurité personnelle des utilisateurs du Site ou du public",
						"Se protéger contre la responsabilité civile",
					],
				},
			},
			data_security: {
				title: "Sécurité des données",
				description:
					"La sécurité de vos données nous tient à cœur. Toutefois, n'oubliez pas qu'aucune méthode de transmission de données par Internet ou méthode de stockage électronique n'est sûre à 100 %. Bien que nous nous efforcions d'utiliser des méthodes commercialement acceptables pour protéger vos Données à Caractère Personnel, nous ne pouvons pas leur garantir une sécurité absolue.",
			},
			user_rights: {
				title: "Droits à la protection des données qui vous sont accordés par le Règlement Général relatif à la Protection des Données (RGPD)",
				description:
					"Si vous résidez dans l'Espace économique européen (EEE), vous avez certains droits en matière de protection des données. La DCF entend prendre des mesures raisonnables pour vous permettre de corriger, de modifier ou de supprimer vos Données à Caractère Personnel ou d'en limiter l'utilisation.",
				contact_info:
					"Si vous souhaitez savoir quelles Données à Caractère Personnel nous détenons à votre sujet et voulez qu'elles soient supprimées de nos systèmes, veuillez nous contacter.",
				rights_list: [
					"Le droit d'accéder aux informations que nous détenons à votre sujet, de les actualiser ou de les supprimer. Lorsque cette option est proposée, vous pouvez consulter ou actualiser vos Données à Caractère Personnel ou en demander la suppression en nous contactant.",
					"Droit de rectification. Vous avez le droit de faire rectifier vos données si elles sont inexactes ou incomplètes.",
					"Droit d'opposition. Vous avez le droit de vous opposer à ce que nous traitions vos Données à Caractère Personnel.",
					"Droit de limitation. Vous avez le droit de nous demander de limiter le traitement de vos données personnelles.",
					"Le droit à la portabilité des données. Vous avez le droit de recevoir une copie des informations que nous détenons à votre sujet dans un format couramment utilisé, structuré et lisible par une machine.",
					"Droit au retrait du consentement. Vous avez également le droit de retirer votre consentement à tout moment si La DCF s'est appuyée sur votre consentement pour traiter vos données personnelles.",
				],
				proof_of_identity: "Veuillez noter que nous pouvons vous demander de prouver votre identité avant de répondre à des demandes de cette nature.",
				complaint:
					"Vous avez le droit de déposer plainte auprès d'une autorité de protection des données au sujet de notre collecte et de notre utilisation de vos Données à Caractère Personnel. Pour plus de précisions, veuillez contacter l'autorité de protection des données la plus proche de vous dans l'Espace économique européen (EEE).",
			},
			service_providers: {
				title: "Prestataires de services",
				description:
					"Nous pouvons faire appel à des sociétés tierces et à des tierces personnes pour faciliter la prestation de notre Site (« Prestataires de Services »), assurer le Site en notre nom, assurer des services liés au Site ou nous aider à analyser la façon dont notre Site est utilisé.",
				access_restriction:
					"Ces tiers n'ont accès à vos Données à Caractère Personnel que pour effectuer ces tâches en notre nom et il leur est interdit de les communiquer ou de les utiliser à quelle qu'autre fin.",
			},
			external_links: {
				title: "Liens pointant vers d'autres sites",
				description:
					"Il se peut que notre Site contienne des liens pointant vers d'autres sites que nous n'exploitons pas. Si vous cliquez sur un lien de tiers, vous serez redirigé vers le site de ce tiers. Nous vous recommandons vivement d'examiner la politique de confidentialité de chacun des sites que vous consultez. Nous n'avons aucun contrôle sur le contenu, les politiques ou pratiques de confidentialité des sites ou services de tiers et déclinons toute responsabilité en ce qui les concerne.",
			},
			policy_updates: {
				title: "Modifications de la présente Politique de Confidentialité",
				description:
					"Nous nous réservons le droit d'actualiser notre Politique de Confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle Politique de Confidentialité sur cette page.",
				notification:
					"Avant que la modification ne prenne effet, nous vous informerons en actualisant la « date de prise d'effet » qui figure en haut de la présente Politique de Confidentialité.",
				advice: "Nous vous conseillons de consulter la présente Politique de Confidentialité périodiquement pour prendre connaissance de toute modification. Les modifications apportées à la présente Politique de Confidentialité prennent effet lorsqu'elles sont publiées sur cette page.",
			},
			contact: {
				title: "Nous contacter",
				description:
					"Pour toute question relative à la présente Politique de Confidentialité ou pour toute demande concernant vos Données à Caractères Personnelles, veuillez nous contacter :",
				contact_info: {
					contact_person: "Contact DPO : Sarah Llouch",
					email: "Par courrier électronique : watchus13@gmail.com",
					address: "Par voie postale : 15 avenue Hoche, 75008 Paris",
				},
			},
			legal_notice: {
				title: "Mentions légales",
				description:
					"Ce site est créé par la société Watch'Us SARL au capital de 45 000€ (R.C.S. Paris B 482 201 407) sise au 15 AV HOCHE 75008 PARIS et présidée par Mme Sarah Lelouch. Adresse email : watchus13@gmail.com",
				publication_director: "Directeur de la publication : Mme Sarah Lelouch.",
				hosting_info: {
					company: "Le site est hébergé par OVH",
					details:
						"SAS au capital de 10 174 560 €\nRCS Lille Métropole 424 761 419 00045\nCode APE 2620Z\nN° TVA : FR 22 424 761 419\nSiège social : 2 rue Kellermann - 59100 Roubaix - France",
					subsidiary:
						"OVH SAS est une filiale de la société OVH Groupe SA société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2 rue Kellermann 59100 Roubaix.",
					publication_director: "Directeur de la publication : Michel Paulin",
				},
			},
		},
		privacy_policy: {
			page_title: "Politique de confidentialité",
			title: "Politique de confidentialité",
			subtitle: "Date d'entrée en vigueur : 19 avril 2024",
			foreword: {
				title: "Préambule",
				content:
					"La présente charte sur le respect de la vie privée formalise notre engagement concernant la protection de la vie privée des utilisateurs du site internet ClapAction exploité par Watch'US. Cette charte constitue le cadre contractuel entre l'utilisateur et Watch'US.",
			},
			data_protection: {
				title: "Respect des normes de protection des données",
				content:
					"Nous traitons vos données personnelles en stricte conformité avec le Règlement Général sur la Protection des Données (RGPD) du 27 avril 2016. Nous nous engageons à ne collecter que des données adéquates, pertinentes et limitées aux nécessités des services proposés, sans jamais inclure de données sensibles.",
			},
			collect: {
				title: "Collecte et utilisation des données personnelles",
				content:
					"Vos données sont collectées lors de la navigation sur le site, la création de compte, les transactions financières ou lorsque vous nous contactez. Ces données sont utilisées exclusivement pour la gestion et l'amélioration de nos services, ainsi que pour la communication relative à ces services.",
			},
			data_security: {
				title: "Conservation et sécurité des données",
				content:
					"Nous appliquons des mesures techniques et organisationnelles avancées pour garantir la sécurité et l'intégrité de vos données personnelles. Toutefois, nous rappelons que la sécurité absolue n'est pas garantie sur Internet. Il est de votre responsabilité de sécuriser également vos identifiants et mots de passe.",
			},
			personal_data: {
				title: "Partage des données personnelles",
				content:
					"Nous ne partageons vos données avec des tiers que pour les nécessités de gestion ou pour répondre à des obligations légales. Aucun transfert hors de l'Union Européenne n'est réalisé sans les garanties appropriées telles que les clauses contractuelles types de la Commission européenne.",
			},
			your_rights: {
				title: "Vos droits",
				content:
					"Vous disposez de droits étendus sur vos données personnelles, incluant l'accès, la correction, la suppression ou la limitation de leur traitement. Vous pouvez exercer ces droits en nous contactant directement par les moyens fournis dans cette charte.",
			},
			chart: {
				title: "Modifications de la charte",
				content:
					"Cette politique peut être mise à jour ; nous vous invitons à consulter régulièrement cette page pour vous tenir informé des changements qui pourraient affecter le traitement de vos données personnelles.",
			},
			contact: {
				title: "Contact et délégué à la protection des données",
				content:
					"Pour toutes questions ou pour exercer vos droits, vous pouvez nous contacter via l'email ou l'adresse postale indiquée. Si applicable, les coordonnées de notre Délégué à la Protection des Données sont également fournies.",
			},
			appeal: {
				title: "Recours",
				content: "En cas de litige, vous avez le droit de saisir la Commission Nationale de l'Informatique et des Libertés (CNIL).",
			},
		},
		profile: {
			page_title: "Profil",
			change_avatar: "Changer la photo de profil",
			cancel_button: "Annuler",
			submit_button: "Appliquer les modifications",
			update_success: "Votre modification a été prise en compte.",
			inputs: {
				firstname: {
					label: "Prénom",
					placeholder: "Jhon",
				},
				lastname: {
					label: "Nom",
					placeholder: "Doe",
				},
				email: {
					label: "Email",
					placeholder: "jhondoe@gmail.com",
				},
				phone: {
					label: "Téléphone",
					placeholder: "06 18 24 32 17",
				},
				birthdate: {
					label: "Date de naissance",
					placeholder: "24/05/1996",
				},
				city: {
					label: "Ville",
					placeholder: "Paris",
				},
				address: {
					label: "Adresse",
					placeholder: "18 rue Maréchal-Juin",
				},
				zipCode: {
					label: "Code postal",
					placeholder: "75 001",
				},
				sex: {
					label: "Sexe",
					options: [
						{
							label: "Homme",
							value: "homme",
						},
						{
							label: "Femme",
							value: "femme",
						},
						{
							label: "Autre",
							value: "autre",
						},
					],
				},
				password: {
					label: "Mot de passe",
					placeholder: "johndoemotdepasse@78",
					additional_info: "Au moins 8 caractères dont 1 majuscule, 1 minuscule et 1 chiffre.",
				},
				lang: {
					label: "Langue du compte",
					options: [
						{
							value: "fr",
							label: "Français",
						},
						{
							value: "en",
							label: "English",
						},
					],
				},
			},
		},
		home: {
			page_title: "Accueil",
			banner: {
				title: "Vos idées prennent vie sur",
				inside_title: "grand écran !",
				subtitle: "Protégez vos idées, participez, et produisez le cinéma de demain !",
				firstContent: [
					{
						text: "Vous pourrez bientôt acquérir des objets uniques et certifiés, directement liés à des films en tournage ou déjà sortis en salle, diffusés à la télé ou sur des plateformes. Pour les films à venir, ces pièces exclusives seront proposées aux enchères, tandis que pour les films déjà exploités, elles seront disponibles à prix fixe. Une opportunité inédite de vous offrir des décors, costumes, affiches dédicacées, ou tout autre élément iconique du 7ᵉ art.",
					},
					{
						text: "Pour les professionnels du cinéma, mettre en vente ces objets n’est pas seulement un moyen de partager des morceaux d’histoire avec le public, c’est aussi une manière innovante de financer leurs projets en développement.",
					},
					{
						text: "Grâce à ClapAction, ces objets sont associés à un NFT garantissant leur authenticité et leur traçabilité, renforçant ainsi leur valeur auprès des collectionneurs et des cinéphiles. C’est une manière innovante et concrète de donner une seconde vie aux objets tout en contribuant à écrire l’avenir du cinéma.",
					},
					{
						text: "La valeur de ces objets ne cessera de croître avec le temps, en fonction du succès des films, de leur impact culturel ou des carrières des artistes associés. Posséder un costume, un décor ou une affiche d’un film culte ou d’un acteur devenu légendaire, c’est non seulement acquérir une œuvre intemporelle, mais aussi investir dans un patrimoine à forte valeur émotionnelle et financière.",
					},
					{
						text: "Avec clapAction, vous avez accès à la toute première Marketplace au monde dédiée à la vente d’objets de cinéma, de manière fiable, sécurisée, et avec une garantie d’authenticité. Bien plus qu’un simple achat, c’est une immersion dans la création cinématographique et un soutien direct aux artistes de demain.",
					},
					{
						text: "Si vous êtes un professionnel et souhaitez faire parti des premiers à mettre en vente l’un de vos objets lié à l’une de vos productions dès le mois de février prochain, contactez-nous : contact@clapaction.com",
					},
				],
				discover_project: "Découvrir les projets",
				submit_project: "Déposer un projet",
			},
			why_join: {
				title: "Pourquoi rejoindre",
				inside_title: "clapAction ?",
				content:
					"Bienvenue sur clapAction, ici vos idées prennent vie grâce à la puissance de l'IA et à la sécurité inégalée de la blockchain. Que vous soyez un créateur passionné ou un fervent supporter de projets innovants, clapAction vous offre une expérience unique et sécurisée.",
				button: "Créer un compte",
				creator_title: "Créateurs",
				creator_content: "Protégez vos idées grâce à la technologie blockchain et, si vous le souhaitez, partagez-les avec des professionnels du cinéma.",
				public_title: "Public",
				public_content: "Exprimez votre soutien en votant pour les projets qui vous inspirent.",
			},
			marketplace: {
				title: "Bientôt : achetez parmi une collection authentique",
				inside_title: "d'objets cinématographiques certifiés",
				subtitle: "Possédez une part de cinéma, soutenez la création",
				firstContent: [
					{
						text: "Vous pourrez bientôt acquérir des objets uniques et certifiés, directement liés à des films en tournage ou déjà sortis en salle, diffusés à la télé ou sur des plateformes. Pour les films à venir, ces pièces exclusives seront proposées aux enchères, tandis que pour les films déjà exploités, elles seront disponibles à prix fixe. Une opportunité inédite de vous offrir des décors, costumes, affiches dédicacées, ou tout autre élément iconique du 7ᵉ art.",
					},
					{
						text: "Pour les professionnels du cinéma, mettre en vente ces objets n’est pas seulement un moyen de partager des morceaux d’histoire avec le public, c’est aussi une manière innovante de financer leurs projets en développement.",
					},
					{
						text: "Grâce à ClapAction, ces objets sont associés à un NFT garantissant leur authenticité et leur traçabilité, renforçant ainsi leur valeur auprès des collectionneurs et des cinéphiles. C’est une manière innovante et concrète de donner une seconde vie aux objets tout en contribuant à écrire l’avenir du cinéma.",
					},
					{
						text: "La valeur de ces objets ne cessera de croître avec le temps, en fonction du succès des films, de leur impact culturel ou des carrières des artistes associés. Posséder un costume, un décor ou une affiche d’un film culte ou d’un acteur devenu légendaire, c’est non seulement acquérir une œuvre intemporelle, mais aussi investir dans un patrimoine à forte valeur émotionnelle et financière.",
					},
					{
						text: "Avec clapAction, vous avez accès à la toute première Marketplace au monde dédiée à la vente d’objets de cinéma, de manière fiable, sécurisée, et avec une garantie d’authenticité. Bien plus qu’un simple achat, c’est une immersion dans la création cinématographique et un soutien direct aux artistes de demain.",
					},
					{
						text: "Si vous êtes un professionnel et souhaitez faire parti des premiers à mettre en vente l’un de vos objets lié à l’une de vos productions dès le mois de février prochain, contactez-nous : contact@clapaction.com",
					},
				],
				explore_title: "Explorez notre boutique",
				explore_content: "Plongez dans notre marketplace dédiée aux passionnés de cinéma.",
				certified_title: "Objets certifiés",
				certified_content: "Une large sélection d'objets authentiques et certifiés sur la blockchain.",
			},
			featured_projects: {
				title: "Projets en",
				inside_title: "développement",
				subtitle: "Explorez les récits captivants et les univers uniques des projets accompagnés par clapAction.",
				discover_project: "Découvrir les projets",
			},
			storytelling: {
				first_block: {
					title: "Passionnés : Participez à la",
					inside_title: "création",
					title_2: "et à la",
					inside_title_2: "production des films",
					subtitle:
						"Votez pour contribuer étape par étape à la production des films : du développement (écriture, casting...) à la sortie en salles, en passant par le tournage et la post-production. Interagissez avec la communauté, et bénéficiez d'avantages exclusifs : accès à des avant-premières, à des tournages, et à vos artistes préférés.",
				},
				second_block: {
					title: "Partagez vos idées",
					inside_title: "en toute sécurité",
					subtitle:
						"Vos documents sont ancrés sur la blockchain, ce qui garantit la préservation et la sécurité intégrale de votre création. Que vous soyez professionnels ou amateurs, partagez vos idées avec l'assurance  qu'elles seront soigneusement protégées à vie.",
					additionnal_subtitle: "NB : vous pouvez choisir de protéger vos idées et de les garder confidentielles, ou de les soumettre à notre comité de sélection.",
				},
				third_block: {
					title: "Professionnels : Consultez",
					inside_title: "l'avis du public",
					title_2: "à tout moment",
					subtitle:
						"Créez une communauté autour de vos projets, accédez à un baromètre d'opinion, soumettez vos productions aux votes du public pour vous orienter dans votre développement.",
				},
			},
			achivement: {
				title: "Notre",
				inside_title: "réalisation phare",
				subtitle: "Celle qui n'avait pas vu Friends",
				content:
					"Greg retrouve sa future femme Rachel quelques minutes avant la cérémonie. Elle est si belle, il a envie de dire « Oh mon Dieu ! » en référence à un personnage dans la série Friends. Rachel ne comprend pas sa blague, elle n'a jamais vu la série. Greg est choqué, il ne veut plus se marier.",
			},
			FAQ: {
				title: "Questions fréquemment posées",
				questions: [
					{
						title: "Comment puis-je déposer une idée de film, un synopsis ou un scénario ?",
						content: "Créez un compte, remplissez le formulaire de dépôt et téléchargez votre PDF, si vous en avez un.",
					},
					{
						title: "Quels sont les critères de sélection des projets ?",
						content: "Les projets sont sélectionnés sur la base de leur originalité, de leur faisabilité et de leur potentiel d’exploitation.",
					},
					{
						title: "Comment est étudié mon projet lorsqu’il est déposé et que vous souhaitez prétendre à une aide au développement ?",
						content:
							"Votre projet est d’abord étudié par le Comité de Lecture interne de LaDCF. S’il est retenu, il est ensuite présenté au Comité de Sélection (composé de 10 personnalités reconnues) qui décide des films dont le développement sera financé et encadré par des professionnels. Découvrez le Comité de Sélection de LaDCF.",
					},
					{
						title: "Comment s’affiche les résultats du Comité de Lecture ?",
						content:
							"Vous pouvez suivre l’avancée du statut de votre projet dans la page « mes projets », grâce à trois icônes : « retenu », « non-retenu », ou « en cours d’étude ».",
					},
					{
						title: "Quel est le délai de réponse du Comité de Lecture ?",
						content:
							"Il n’y a pas de délai spécifié par le Comité de Lecture. En moyenne, un projet met 1 mois à être étudié, mais ce temps peut varier selon la quantité de projets reçus chaque semaine. Pour information le Comité de Sélection se réunit chaque trimestre.",
					},
					{
						title: "Comment puis-je voter pour un projet ?",
						content: "Connectez-vous à votre compte, naviguez vers la page des projets et votez via les options disponibles.",
					},
					{
						title: "Le nombre de dépôts de projet est-il limité ?",
						content:
							"Le nombre de projets que vous pouvez déposer sur la plateforme n’est pas limité, vous pouvez déposer autant de projets que vous le souhaitez et bénéficier d’une authentification sur la blockchain. Tous les dépôts de projets ont une valeur juridique.",
					},
					{
						title: "Peut-on modifier ou supprimer un projet déposé ?",
						content:
							"Tant que le projet n’est pas ancré sur la blockchain, le projet est modifiable. Une fois ancré, il sera inscrit à tout jamais sur la blockchain et par conséquent, il ne sera plus modifiable ou supprimable.",
					},
					{
						title: "Puis-je déposer une vidéo avec mon projet ?",
						content:
							"Vous ne pouvez pas déposer de vidéos directement sur la plateforme, mais vous pouvez inclure un lien renvoyant vers la vidéo dans votre Synopsis ou votre CV.",
					},
					{
						title: "La plateforme aide-t-elle des particuliers à trouver un producteur ?",
						content:
							"Si vous déposez un projet en tant que particulier, il sera automatiquement étudié par le Comité de LaDCF. Si vous êtes un auteur, réalisateur ou producteur, vous faites parti de la catégorie des professionnels. En publiant votre projet sur la plateforme vous pouvez trouver des producteurs, diffuseurs, distributeurs, via l’espace professionnel, interagir avec le public et créer une communauté autour de votre projet et ce dès le stade du développement.",
					},
				],
			},
		},
		confirmation_code: {
			page_title: "Confirmation du code",
			title: "Code d'authentification",
			subtitle: "Entrez le code OTP que nous venons de vous envoyer sur votre adresse e-mail enregistré",
			button: "Se connecter",
			send_code: "Envoyer un nouveau code",
		},
		my_projects: {
			page_title: "Mes projets déposés",
			title: "Mes projets déposés",
			filters: {
				all_projects: "Tous les projets",
				accepted: "Retenu",
				refused: "Non retenu",
				studying: "En cours d'étude",
				anchored: "Ancré",
				not_anchored: "En attente d'ancrage",
				anchor_failed: "Ancrage échoué",
			},
			table: {
				headers: {
					project_name: "Nom du projet",
					ai_image: "Image IA",
					anchor_proof: "Certificat",
					status: "Status",
					date: "Date",
				},
				waiting: "En attente...",
				see_image: "Voir l'image",
				anchor_link: "Lien d'ancrage",
				download: "Télécharger",
				anchor: "Ancrer",
				menu: {
					anchor: "Ancrer le projet",
					download_proof: "Télécharger la preuve d'ancrage",
					data: "Informations sur le projet",
					edit: "Modifier le projet",
				},
			},
			drawer: {
				button: {
					anchor: "Ancrer",
					modify: "Modifier mon projet",
					modify_socials: "Modifier les liens",
					download_proof: "Télécharger le certificat",
				},
				modal: {
					title: "Êtes-vous sûr de vouloir ancrer ce projet ?",
					content1:
						"L’ancrage est une action permanente qui verrouille votre projet dans son état actuel. Une fois que vous avez ancré le projet, vous ne pourrez plus modifier son contenu. Cette opération est irréversible et garantit que les données ne pourront pas être altérées par la suite.",
					content2: "Veuillez vous assurer que toutes les modifications nécessaires ont été effectuées avant de confirmer cette action.",
					button: {
						confirm: "Ancrer",
						cancel: "Annuler",
					},
				},
			},
		},
		submit_project: {
			page_title: "Déposer un projet",
			title: "Déposer un projet",
			subtitle: "protégé, sécurisé, et certifié",
			description: {
				items: [
					{
						text: "Partagez vos idées en toute sérénité en remplissant les champs ci-dessous.",
					},
					{
						text: "Grâce à notre technologie, chaque projet déposé est automatiquement <b>ancré sur la blockchain</b>. Une fois votre projet ancré, vous recevrez un certificat d’authenticité pour garantir la protection de votre création, quelle que soit la raison de votre dépôt.",
					},
					{
						text: "De plus, une affiche de film générée par notre Intelligence Artificielle vous sera proposée en exclusivité. Un outil inspirant, conçu pour donner vie à votre vision.",
					},
					{
						text: "Votre projet reste <b>strictement confidentiel</b> : aucune information ne sera partagée sans votre accord.",
					},
				],
			},
			labels: {
				project_title: "Titre du projet",
				project_title_placeholder: "100 caractères maximum",
				project_pitch: "Pitch",
				project_format: "Format",
				project_reason: "Quelle est la raison principale pour laquelle vous déposez votre projet ?",
				project_pitch_additionnal:
					"Veuillez fournir une description concise et attrayante. Cette description sera utilisée pour mettre en avant votre projet sur la plateforme.",
				project_pitch_placeholder: "Pitch (150 caractères minimum)",
				project_linkedin: "Veuillez fournir un lien LinkedIn si vous ne soumettez pas votre CV",
				cv: "CV",
				scenario: "Synopsis et/ou éléments artistiques",
			},
			selections: {
				format: {
					selectAnOption: "Sélectionner un format",
					long_metrage: "Long métrage",
					court_metrage: "Court métrage",
					serie: "Série",
					documentaire: "Documentaire",
				},
				reason: {
					protect: "Je dépose mon projet simplement pour le protéger",
					protect_and_development_aid: "Je dépose mon projet pour le protéger et le soumettre à l'aide au développement",
				},
				purposes: {
					funding: "Soumettre mon projet au comité de sélection de LaDCF (La Diversité du Cinéma Français)",
					funding_comment: "(Pour prétendre à une aide au développement)",
					protect: "Obtenir un certificat d’authenticité et protéger mon idée",
					pitch: "Participer à une sélection pour présenter mon projet à des professionnels avec Neeedco",
				},
			},
			drag_and_drop: {
				cv: "Glisser ou cliquer ici pour télécharger le CV",
				scenario: "Glisser ou cliquer ici pour télécharger votre document",
			},
			confidentiality: {
				scenarioProtection: {
					text: "NB : En déposant votre projet sur clapAction, les éléments de votre projet (scénario, synopsis, dossier, etc.) seront protégés sur la blockchain. Si vous souhaitez que votre projet soit étudié par le Comité de Sélection, nous vous invitons à limiter votre présentation à 2 pages dactylographiées.",
				},
				projectSharing: {
					text: "Si vous souhaitez déposer votre projet afin de le partager publiquement avec la communauté, rdv sur",
					suscribePageLink: "l'espace dédié au professionnels.",
				},
			},
			send_button: "Déposer le projet",
		},
		edit_project: {
			page_title: "Modifier le projet",
			title: "Modifier le projet",
			subtitle: "Modifiez les informations de votre projet en remplissant les champs ci-dessous.",
			labels: {
				project_title: "Titre du projet",
				project_pitch: "Pitch",
				project_format: "Format",
				project_reason: "Quelle est la raison principale pour laquelle vous déposez votre projet ?",
				project_pitch_additionnal:
					"Veuillez fournir une description concise et attrayante. Cette description sera utilisée pour mettre en avant votre projet sur la plateforme.",
				project_pitch_placeholder: "Pitch (150 caractères minimum)",
				project_linkedin: "Veuillez fournir un lien LinkedIn si vous ne soumettez pas votre CV",
				cv: "CV",
				scenario: "Synopsis et/ou éléments artistiques",
				purpose: "Pourquoi déposez-vous votre projet ? (Vous pouvez cocher plusieurs options)",
			},
			selections: {
				format: {
					long_metrage: "Long métrage",
					court_metrage: "Court métrage",
					serie: "Série",
					documentaire: "Documentaire",
				},
				reason: {
					protect: "Je dépose mon projet simplement pour le protéger",
					protect_and_development_aid: "Je dépose mon projet pour le protéger et le soumettre à l'aide au développement",
				},
			},
			drag_and_drop: {
				cv: "Glisser ou cliquer ici pour télécharger le CV",
				scenario: "Glisser ou cliquer ici pour télécharger votre document",
			},
			confidentiality:
				"NB : En déposant votre projet sur clapAction, le scénario sera protégé sur la blockchain. Si vous souhaitez que votre projet soit étudié par le Comité de Sélection, nous vous invitons à ne pas dépasser les 2 pages dactylographiées.",
			send_button: "Modifier le projet",
		},
		contact: {
			page_title: "Contact",
			title: "Contactez-nous",
			subtitle: "Besoin d'un renseignement ? Envoyez-nous vos questions ou commentaires, nous vous répondrons rapidement !",
			labels: {
				subject: "Sujet",
				email: "Email",
				message: "Message",
				firstname: "Prénom",
				lastname: "Nom",
				phone: "Téléphone",
			},
			confirmation: "Merci pour votre message, nous vous répondrons dans les plus brefs délais.",
			button: "Envoyer",
		},
		subscribe: {
			page_title: "Abonnez-vous",
			title: "Aller chercher votre public, apprenez à le connaître, découvrez ses attentes et offrez une",
			inside_title: "communication impactante et inédites à vos projets !",
			box: "Vous êtes un professionnel : auteur, réalisateur, producteur, diffuseur ou distributeur ?",
			subtitle: "Obtenez des insights précieux sur votre public et influencez l'industrie",
			description: {
				title: "Vous développez des films\u00A0?",
				content1: "Avec clapAction créez une communauté autour de vos projets et donnez-leur de la notoriété, bien avant sa sortie !",
				content2:
					"Partagez vos films à n’importe quelle étape de sa production : de l’idée à sa sortie. Ainsi, découvrez les attentes du public, impliquez-le dans votre processus de développement et de création, consultez ses datas et laissez votre communauté faire le bouche-à-oreille dès la naissance de votre projet.",
			},
			button_box: {
				title: "Abonnez-vous dès maintenant !",
				content1: "Dès le mois de juin prochain, vous pourrez partager en exclusivité, les projets qui vous animent aujourd'hui pour en faire les succès de demain.",
				content2: "L’attente est l’écrin du bonheur… clapAction en est un.",
				button: "Abonnez-vous",
			},
			abilities_title: "Tous les professionnels pourront :",
			deposit:
				"Déposer leur(s) film(s) sur la plateforme à n’importe quel stade de sa production (du développement à sa sortie) afin de créer une communauté et la consulter dès qu’ils le souhaitent",
			federate: "Fédérer et engager une communauté autour de chaque projet en commençant par profiter de toute la communauté de clapAction.",
			contribute:
				"Solliciter le public pour n’importe quel sujet/question (votes, recherches d’idées, appel à témoignages en lien avec leur sujet, aide au casting, choix du titre, affiches, musiques etc…)",
			use_data: "Avoir accès à l’ensemble des datas liées à leur projet.",
			deposit_content: "Déposer et partager les contenus de leur choix liés à leur(s) projets(s) : vidéos, coulisses, itv, reels etc…",
			offer: "Offrir, si le projet en a déjà, des liens directs à leurs réseaux sociaux.",
			drive: "Accompagner la création de chaque communauté, de façon singulière et adaptée à leur sujet.",
			shape: "Façonner leurs projets en fonction de l’attente du public",
			types: {
				title: "Types :",
				type1: "Genre (Homme/femme)",
				type2: "Tranche d’âge",
				type3: "Lieux de résidence",
				type4: "Genre de films qu’ils aiment",
				type5: "Contenus crées par leur communauté, liés à leur projet",
				type6: "Pourcentage d’intérêt pour leur projet par rapport à l’ensemble des films sur clapAction",
			},
			contact: "Nous contacter",
			radio: {
				title: "Vous souhaitez",
				options: {
					build: "Partager un film, construire et interagir avec sa communauté et apprendre à la connaître",
					access: "Partager plusieurs films et avoir accès à toutes les données et à toutes les attentes du public",
				},
			},
			labels: {
				firstname: "Prénom",
				lastname: "Nom",
				address: "Adresse",
				phone: "Téléphone",
				email: "Email",
				message: "Vous avez une question, envoyez-nous un message",
			},
			confirmation: "Merci pour votre message, nous vous répondrons dans les plus brefs délais.",
			button: "Envoyer",
		},
		dashboard: {
			page_title: "Tableau de bord",
		},
		reset_password: {
			page_title: "Réinitialisation du mot de passe",
			title: {
				first: "Réinitialisation du",
				second: "mot de passe",
			},
			subtitle: "Veuillez saisir un nouveau mot de passe",
			labels: {
				password: "Mot de passe",
				password_additionnal: "Au moins 8 caractères",
				password_confirm: "Confirmer le mot de passe",
			},
			reset_button: "Changer de mot de passe",
		},
		legal_notice: {
			page_title: "Mentions légales",
			title: "Mentions légales",
			subtitle:
				"Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site clapAction l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.",
			edition: {
				title: "Edition du site",
				url: "Le présent site, accessible à l'URL https://clapaction.app/ (le « Site »), est édité par :",
				company:
					"CLAPACTION, société au capital de 1000 euros, inscrite au R.C.S. de PARIS sous le numéro 934 285 610 R.C.S Paris, dont le siège social est situé au 28 rue de Turin 75008 Paris, représenté(e) par Sarah Lelouch dûment habilité(e)",
				tva: "Le numéro individuel TVA de l'éditeur est : FR35934285610.",
			},
			hosting: {
				title: "Hébergement",
				hosted: "Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).",
			},
			publication: {
				title: "Directeur de publication",
				content: "Le Directeur de la publication du Site est Sarah Lelouch.",
			},
			contact: {
				title: "Nous contacter",
				mail: "Par email : contact@clapaction.com",
				letter: "Par courrier : 15 AVENUE HOCHE, 75008 PARIS",
				legal: "Génération des mentions légales par Legalstart",
			},
		},
		forgot_password: {
			page_title: "Mot de passe oublié",
			title: "Mot de passe oublié ?",
			labels: {
				email: "Email",
				email_additionnal: "*Nous allons envoyer un lien par mail pour réinitialiser votre mot de passe",
			},
			send_button: "Envoyer le lien par mail",
			redirect_link: "Retour",
			confirmation: "Un email vous a été envoyé avec un code de réinitialisation de Password.",
		},
		login: {
			page_title: "Se connecter",
			title: "Se connecter",
			subtitle: "",
			labels: {
				email: "Email",
				password: "Mot de passe",
			},
			password_forgotten: "Mot de passe oublié ?",
			login_button: "Se connecter",
			redirect_text: "Vous n'avez pas de compte ?",
			redirect_link: "S'inscrire",
		},
		projects: {
			page_title: "Les projets",
			title: "Découvrez les films accompagnés par",
			inside_title: "clapAction",
		},
		complete_profile: {
			page_title: "Complétez votre profil",
			title: "Avant de déposer un projet complétez votre profil",
			labels: {
				firstname: "Prénom",
				lastname: "Nom",
				pseudo: "Pseudo",
				email: "Email",
				password: "Mot de passe",
				password_confirmation: "Confirmer le mot de passe",
				password_additionnal: "Au moins 8 caractères",
				checkbox: "J'accepte les conditions d'utilisation",
				zipcode: "Code postal",
				city: "Ville",
				address: "Adresse",
				phoneNumber: "Téléphone",
			},
			next_button: "Suivant",
		},
		register: {
			page_title: "S'incrire",
			title: "S'inscrire",
			subtitle:
				"Rejoignez clapAction : proposez vos idées de films ou de séries en toute confiance, et participez à la création des projets de clapAction. Tous les champs ci-dessous sont obligatoires.",
			inputs: {
				firstname: {
					label: "Prénom",
				},
				lastname: {
					label: "Nom",
				},
				email: {
					label: "Email",
				},
				password: {
					label: "Mot de passe",
					additionnal_info: "Au moins 8 caractères",
				},
				confirm_password: {
					label: "Confirmer le mot de passe",
				},
				lang: {
					label: "Langue du compte",
					options: [
						{
							value: "fr",
							label: "Français",
						},
						{
							value: "en",
							label: "English",
						},
					],
				},
				terms_of_use: {
					label: "J'accepte les conditions d'utilisation",
				},
			},
			register_button: "S'inscrire",
			redirect_text: "Vous avez déjà un compte ?",
			redirect_link: "Se connecter",
		},
		post: {
			return: "Retour",
			title: "Forum",
			comment: {
				submit: "Publier",
				placeholder: "Partagez votre avis...",
				default_subtitle: "Échangeons ensemble",
				empty: "Aucun commentaire",
				share: "Soyez le premier à partager votre avis !",
				reply: {
					delete: "Supprimer",
					submit: "Répondre",
					many_replies: "réponses",
					single_reply: "réponse",
					your_reply: "Votre réponse...",
				},
			},
		},
		prix_alice_guy: {
			page_title: "Prix Alice Guy",
			header: {
				publication: "Publication: du 1er décembre 2024 au 31 janvier 2025",
				genre: "Genre: Films de réalisatrice",
				format: "Format: Vote",
			},
			content: {
				title: "Votez pour le Prix Alice Guy 2025",
				description: `Le Prix Alice Guy récompense, depuis 2018, le meilleur film français réalisé par une femme et sorti au cinéma dans l'année. Tout le monde peut contribuer à le choisir. Comment ? En votant pour vos 5 films préférés dans la liste ci-dessous.`,
				sub_description: "Vous avez jusqu'au 31 janvier 2025 pour faire votre choix.",
			},
			buttons: {
				vote: "Participer au vote",
				discover: "Découvrir la page Alice Guy",
			},
			sections: {
				first: {
					title: "Pour voter, rien de plus simple.",
					description: `Tous les films éligibles (ils sont <strong>85 cette année</strong>) figurent dans la liste fournie. Cliquez sur  “Je vote” et cochez 3 films au minimum et 5 au maximum pour que votre vote soit enregistré. Tout le monde peut voter… mais chacun.e ne peut voter qu’une seule fois. <br/>Pour valider votre choix, il suffit de laisser votre adresse email. <br/><br/><strong>L’urne virtuelle se refermera le 31 janvier 2025 à minuit.</strong>`,
				},
				second: {
					title: "Et après?",
					list: {
						first: "Le 1er février 2025, les 5 films qui auront reçu le plus voix (1 vote = 1 voix) seront annoncés. Fin février, ils seront départagés par un jury paritaire de 6 professionnels du cinéma.",
						second: "Une soirée de remise du Prix Alice Guy sera ensuite organisée à Paris pour honorer la lauréate, la mémoire et le travail d’Alice Guy, première réalisatrice au monde et les 130 ans du cinéma. Et comme chaque année, la soirée sera ouverte à tout.es.",
					},
				},
			},
		},
	},
	component: {
		theme_selector: {
			title: "Genre",
			select: "Sélectionner le genre correspondant",
			themes: {
				comedie: "Comédie",
				comedie_romantique: "Comédie romantique",
				comedie_dramatique: "Comédie dramatique",
				biopic: "Biopic",
				thriller: "Thriller",
				film_daction: "Film d'action",
				film_policier: "Film policier",
				film_dhorreur: "Film d'horreur",
				film_historique: "Film historique",
				science_fiction: "Science fiction",
				film_danimation: "Film d'animation",
				comedie_musicale: "Comédie musicale",
				dramédie: "Dramédie",
				comedie_absurde: "Comédie absurde",
				thriller_psychologique: "Thriller psychologique",
			},
		},
		featured_project: {
			project_leader: "Porteur de projet :",
			published_at: "Publication : le {{var1}}",
			category: "Genre :",
			length: "Format :",
			button: "Voir le projet",
		},
		drag_and_drop_file: {
			no_file_selected: "Aucun fichier sélectionné",
		},
		authpagetemplate: {
			footer: {
				copyright: "© 2024 clapAction",
			},
		},
		lang_selector: {
			fr: "FR",
			en: "EN",
		},
		header: {
			login: "Se connecter",
			register: "S'inscrire",
			submit_project: "Déposer un projet",
			my_projects: "Mes projets déposés",
			personnal_informations: "Informations personnelles",
			home: "Accueil",
			marketplace: "Marketplace",
			projects: "Les projets",
			contact: "Contact",
			subscribe: "Espace professionnel",
			all_projects: "Tous les projets",
			logout: "Se déconnecter",
			coming_soon: {
				title: "Votez pour le Prix Alice Guy 2025",
				message: "Depuis 2018, le Prix Alice Guy récompense le meilleur film français réalisé par une femme.",
				submessage: "Participez en votant pour vos 5 films préférés avant le 31 janvier 2025.",
				button: "Je participe au vote",
			},
		},
		footer: {
			social_links: "Réseaux sociaux",
			neeedco: "Le réseau social du cinéma",
			dcf: "Le comité de sélection",
			contact: "Contact",
			legal_notice: "Mentions légales",
			privacy_policy: "Politique de confidentialité",
			terms_of_use: "Conditions d'utilisation",
			copyright: "Copyright © {{year}} clapAction",
		},
		suscribe: {
			suscribe_newsletter: "S'inscrire à la newsletter",
			suscribed_newsletter: "Vous êtes bien inscrit à la newsletter clapAction !",
		},
	},
	enums: {
		EValidationErrors: EValidationErrorImplementation,
		EProjectThemes: EProjectThemeImplementation,
		EProjectFormats: EProjectFormatImplementation,
		EProjectReasons: EProjectReasonImplementation,
		EProjectPurposes: EProjectPurposeImplementation,
	},
};

export default trads;
