import AppDescriptionCard from "./AppDescriptionCard";
import SubscribeCard from "./SubscribeCard";
import classes from "./classes.module.scss";

type IProps = {
	onClickSubscribe: () => void;
};

export default function CardGrid(props: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["description"]}>
				<AppDescriptionCard />
				<SubscribeCard onClick={props.onClickSubscribe} />
			</div>
		</div>
	);
}
