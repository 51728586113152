import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { useRef } from "react";
import classes from "./classes.module.scss";
import Footer from "components/materials/Footer";
import Header from "components/materials/Header";
import CardGrid from "./CardGrid";
import Abilities from "./Abilities";
import SubscribeForm from "./SubscribeForm";

export default function Subscribe() {
	const formRef = useRef<HTMLDivElement>(null);

	const handleScroll = () => {
		formRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div className={classes["root"]}>
			<Header />
			<div className={classes["main"]}>
				<div className={classes["content"]}>
					<div className={classes["header-container"]}>
						<div className={classes["box"]}>
							<Typography type="p" weight="bold" size="xlarge" color="secondary">
								{I18n.trslt(I18n.asset.pages.subscribe.box)}
							</Typography>
						</div>

						<div className={classes["header"]}>
							<Typography type="h1" weight="bold">
								{I18n.trslt(I18n.asset.pages.subscribe.title)}
								<Typography type="span" weight="bold" color="primary" className={classes["secondary-title"]}>
									&nbsp;{I18n.trslt(I18n.asset.pages.subscribe.inside_title)}
								</Typography>
							</Typography>

							<div className={classes["separator"]} />

							<CardGrid onClickSubscribe={handleScroll} />
						</div>
					</div>

					<Abilities />
				</div>
			</div>
			<div ref={formRef}>
				<SubscribeForm />
			</div>
			<Footer />
		</div>
	);
}
