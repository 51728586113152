import SubscribeRequestResource from "common/resources/Form/SubscribeRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import Input from "components/materials/Form/InputElement";
import TextArea from "components/materials/Form/TextAreaElement";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";
import FormService from "services/FormService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import ModuleConfig from "configs/ModuleConfig";
import { useNavigate } from "react-router-dom";
import Radio from "components/materials/Form/RadioElement";

const formService = container.resolve(FormService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function SubscribeForm() {
	const navigate = useNavigate();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);

			const subscribeRequest = SubscribeRequestResource.hydrate<SubscribeRequestResource>({
				email: formData["email"] as string,
				message: formData["message"] as string,
				firstName: formData["firstName"] as string,
				lastName: formData["lastName"] as string,
				reason: formData["reason"] as string,
				phone: formData["phone"] as string,
				lang: I18n.getLang(),
			});

			subscribeRequest
				.validateOrReject()
				.then(() => {
					formService.subscribe(subscribeRequest).then(() => {
						alert(I18n.trslt(I18n.asset.pages.subscribe.confirmation));
						navigate(modules.pages.Home.props.path);
					});
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[navigate],
	);

	return (
		<div className={classes["root"]}>
			<Typography type="h2" weight="bold">
				{I18n.trslt(I18n.asset.pages.subscribe.contact)}
			</Typography>
			<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
				<div className={classes["input-container"]}>
					<div className={classes["input-row-wrapper"]}>
						<Input name="firstName" label={I18n.trslt(I18n.asset.pages.subscribe.labels.firstname)} required className={classes["input"]} />
						<Input name="lastName" label={I18n.trslt(I18n.asset.pages.subscribe.labels.lastname)} required className={classes["input"]} />
					</div>
					<Input name="address" label={I18n.trslt(I18n.asset.pages.subscribe.labels.address)} required className={classes["input"]} />
					<Input name="phone" label={I18n.trslt(I18n.asset.pages.subscribe.labels.phone)} required className={classes["input"]} />
					<Input placeholder="johndoe@gmail.com" name="email" label={I18n.trslt(I18n.asset.pages.subscribe.labels.email)} required className={classes["input"]} />
					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.subscribe.radio.title)}
					</Typography>
					<Radio
						required
						name="reason"
						value={I18n.trslt(I18n.asset.pages.subscribe.radio.options.build)}
						label={I18n.trslt(I18n.asset.pages.subscribe.radio.options.build)}
					/>
					<Radio
						required
						name="reason"
						value={I18n.trslt(I18n.asset.pages.subscribe.radio.options.access)}
						label={I18n.trslt(I18n.asset.pages.subscribe.radio.options.access)}
					/>
					<TextArea
						placeholder="Type your message..."
						name="message"
						label={I18n.trslt(I18n.asset.pages.subscribe.labels.message)}
						className={classes["textarea-container"]}
					/>
				</div>

				<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} type="submit" fullwidth>
					{I18n.trslt(I18n.asset.pages.contact.button)}
				</Button>
			</Form>
		</div>
	);
}
