import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";

export default function AppDescriptionCard() {
	return (
		<div className={classes["root"]}>
			<Typography type="h2" weight="bold">
				{I18n.trslt(I18n.asset.pages.subscribe.description.title)}
			</Typography>
			<Typography type="p" size="medium" weight="regular">
				{I18n.trslt(I18n.asset.pages.subscribe.description.content1)}
			</Typography>
			<Typography type="p" size="medium" weight="regular">
				{I18n.trslt(I18n.asset.pages.subscribe.description.content2)}
			</Typography>
		</div>
	);
}
