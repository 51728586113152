import { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { container } from "tsyringe";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { HeaderContext } from "contexts/Header";
import classes from "./classes.module.scss";

const modules = container.resolve(ModuleConfig).get().modules;

export default function ComingSoonContent() {
	const location = useLocation();
	const { setIsComingSoonOpen } = useContext(HeaderContext);

	useEffect(() => {
		if (location.pathname === modules.pages.PrixAliceGuy.props.path) setIsComingSoonOpen(false);
	}, [location.pathname, setIsComingSoonOpen]);

	return (
		<div className={classes["root"]}>
			<div className={classes["title"]}>
				<Typography type="span" size="medium" weight="bold" color="white">
					{I18n.trslt(I18n.asset.component.header.coming_soon.title)}
				</Typography>
			</div>
			<div className={classes["message"]}>
				<Typography type="span" size="medium" weight="regular" color="white" htmlContent={I18n.trslt(I18n.asset.component.header.coming_soon.message)}></Typography>
			</div>
			<div className={classes["message"]}>
				<Typography type="span" size="medium" weight="regular" color="white" htmlContent={I18n.trslt(I18n.asset.component.header.coming_soon.submessage)}></Typography>
			</div>
			<Link to={modules.pages.PrixAliceGuy.props.path} className={classes["link"]}>
				<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY}>
					{I18n.trslt(I18n.asset.component.header.coming_soon.button)}
				</Button>
			</Link>
		</div>
	);
}
