import { ReactComponent as Discord } from "assets/images/socials/discord.svg";
import { ReactComponent as Instagram } from "assets/images/socials/instagram.svg";
import { ReactComponent as X } from "assets/images/socials/X.svg";
import { ReactComponent as Linkedin } from "assets/images/socials/linkedin.svg";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import Typography from "components/elements/Typography";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import classes from "./classes.module.scss";

export type IProps = {
	project: ProjectResponseResource;
};

export default function Social(props: IProps) {
	const openDiscordLink = () => {
		window.open(props.project.discord!, "_blank");
	};
	const openInstagramLink = () => {
		window.open(props.project.instagram!, "_blank");
	};
	const openXLink = () => {
		window.open(props.project.x!, "_blank");
	};
	const openLinkedinLink = () => {
		window.open(props.project.linkedin!, "_blank");
	};

	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				{props.project.discord && (
					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.NEUTRAL} startIcon={<Discord />} onClick={openDiscordLink} className={classes["button"]}>
						<Typography className={classes["button-text"]}>Discord</Typography>
					</Button>
				)}
				{props.project.instagram && (
					<Button startIcon={<Instagram />} onClick={openInstagramLink} className={classes["button"]}>
						<Typography className={classes["button-text"]}>Instagram</Typography>
					</Button>
				)}
				{props.project.x && (
					<Button startIcon={<X />} onClick={openXLink} className={classes["button"]}>
						<Typography className={classes["button-text"]}>X</Typography>
					</Button>
				)}
				{props.project.linkedin && (
					<Button startIcon={<Linkedin />} onClick={openLinkedinLink} className={classes["button"]}>
						<Typography className={classes["button-text"]}>Linkedin</Typography>
					</Button>
				)}
			</div>
		</div>
	);
}
