import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";

type IProps = {
	onClick: () => void;
};

export default function Subscribe(props: IProps) {
	return (
		<div className={classes["root"]}>
			<Typography type="h2" weight="bold">
				{I18n.trslt(I18n.asset.pages.subscribe.button_box.title)}
			</Typography>
			<Typography type="p" size="medium" weight="regular">
				{I18n.trslt(I18n.asset.pages.subscribe.button_box.content1)}
			</Typography>
			<Typography type="p" size="medium" weight="bold">
				{I18n.trslt(I18n.asset.pages.subscribe.button_box.content2)}
			</Typography>
			<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} fullwidth onClick={props.onClick}>
				{I18n.trslt(I18n.asset.pages.subscribe.button_box.button)}
			</Button>
		</div>
	);
}
