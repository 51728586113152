import { useCallback, useState } from "react";
import { container } from "tsyringe";
import { match } from "ts-pattern";
import { EGender } from "common/enums/Gender/EGenter";
import { ELang } from "common/enums/User/ELang";
import { ValidationError } from "common/resources/Resource";
import UserRegisterRequestResource from "common/resources/User/UserRegisterRequestResource";
import AuthPageTemplate from "components/PageTemplate/AuthPageTemplate";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Checkbox from "components/elements/Checkbox";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import Input from "components/materials/Form/InputElement";
import Select from "components/materials/Form/SelectElement";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import useNavigateKeepSearchParams from "hooks/useNavigateKeepSearchParams";
import AuthService from "services/AuthService";
import classes from "./classes.module.scss";

const modules = container.resolve(ModuleConfig).get().modules;
const authService = container.resolve(AuthService);

export default function Register() {
	const navigate = useNavigateKeepSearchParams();
	const { hasAcceptedConditions, handleAcceptConditions } = useAcceptConditions();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const redirectToLoginPage = useCallback(() => {
		navigate(modules.pages.Login.props.path);
	}, [navigate]);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);

			const parseLang = (lang: string | null): ELang | null => {
				return match(lang)
					.with("en", () => ELang.EN)
					.with("fr", () => ELang.FR)
					.otherwise(() => null);
			};

			const userRequest = UserRegisterRequestResource.hydrate<UserRegisterRequestResource>({
				email: formData["email"] as string,
				firstName: formData["firstName"] as string,
				lastName: formData["lastName"] as string,
				password: formData["password"] as string,
				confirmPassword: formData["confirmPassword"] as string,
				phoneNumber: formData["phoneNumber"] as string,
				address: formData["address"] as string,
				zipCode: formData["zipCode"] as string,
				city: formData["city"] as string,
				gender: formData["gender"] as EGender,
				birthDate: formData["birthdate"] ? new Date(formData["birthdate"] as string) : undefined,
				lang: parseLang(formData["lang"] as string) ?? ELang.FR,
			});

			userRequest
				.validateOrReject()
				.then(() => {
					authService
						.register(userRequest)
						.then(() => {
							navigate(modules.pages.ConfirmationCode.props.path);
						})
						.catch((error) => {
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[navigate],
	);

	return (
		<AuthPageTemplate tabTitle={I18n.trslt(I18n.asset.pages.register.page_title)}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.register.title)}
					</Typography>
					<Typography type="p" weight="regular" size="large">
						{I18n.trslt(I18n.asset.pages.register.subtitle)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<div className={classes["input-name-container"]}>
						<Input
							name="firstName"
							label={I18n.trslt(I18n.asset.pages.register.inputs.firstname.label)}
							required
							className={classes["input"]}
							inputClassName={classes["input-content"]}
						/>
						<Input
							name="lastName"
							label={I18n.trslt(I18n.asset.pages.register.inputs.lastname.label)}
							required
							className={classes["input"]}
							inputClassName={classes["input-content"]}
						/>
					</div>
					<Input
						name="email"
						label={I18n.trslt(I18n.asset.pages.register.inputs.email.label)}
						required
						className={classes["input"]}
						inputClassName={classes["input-content"]}
					/>
					<Input
						name="password"
						label={I18n.trslt(I18n.asset.pages.register.inputs.password.label)}
						required
						type="password"
						className={classes["input"]}
						inputClassName={classes["input-content"]}
					/>
					<Input
						name="confirmPassword"
						label={I18n.trslt(I18n.asset.pages.register.inputs.confirm_password.label)}
						required
						type="password"
						additionalInfo={I18n.trslt(I18n.asset.pages.register.inputs.password.additionnal_info)}
						className={classes["input"]}
						inputClassName={classes["input-content"]}
					/>
					<Select
						name="lang"
						label={I18n.trslt(I18n.asset.pages.register.inputs.lang.label)}
						options={I18n.asset.pages.register.inputs.lang.options}
						className={classes["input"]}
						defaultValue={I18n.getLang()}
					/>

					<div className={classes["submit-container"]}>
						<div className={classes["buttons-container"]}>
							<Checkbox
								checked={hasAcceptedConditions}
								onChange={handleAcceptConditions}
								uid="conditions"
								label={I18n.trslt(I18n.asset.pages.register.inputs.terms_of_use.label)}
							/>
							<Button
								variant={EButtonVariant.CONTAINED}
								color={EButtonColor.PRIMARY}
								size={EButtonSize.LARGE}
								fullwidth
								disabled={!hasAcceptedConditions}
								type="submit">
								{I18n.trslt(I18n.asset.pages.register.register_button)}
							</Button>
						</div>

						<div className={classes["login-redirect-container"]}>
							<Typography type="p" weight="regular" size="medium">
								{I18n.trslt(I18n.asset.pages.register.redirect_text)}
							</Typography>
							<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM} onClick={redirectToLoginPage}>
								{I18n.trslt(I18n.asset.pages.register.redirect_link)}
							</Button>
						</div>
					</div>
				</Form>
			</div>
		</AuthPageTemplate>
	);
}

const useAcceptConditions = () => {
	const [hasAcceptedConditions, setHasAcceptedConditions] = useState<boolean>(false);

	const handleAcceptConditions = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setHasAcceptedConditions(e.currentTarget.checked);
	}, []);

	return {
		hasAcceptedConditions,
		handleAcceptConditions,
	};
};
