import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { Link, useNavigate } from "react-router-dom";
import classes from "./classes.module.scss";
import { Bars3Icon } from "@heroicons/react/20/solid";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import { useCallback, useEffect, useRef, useState } from "react";
import UserAccountInfoResponseResource from "common/resources/User/UserAccountInfoResponseResource";
import NotConnectedHeader from "../NotConnectedHeader";
import AuthService from "services/AuthService";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/20/solid";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import HasRules from "components/materials/HasRules";

const modules = container.resolve(ModuleConfig).get().modules;
const authService = container.resolve(AuthService);

type IProps = {
	user: UserAccountInfoResponseResource | null;
};

export default function ResponsiveMenu(props: IProps) {
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const toggleMenu = useCallback(() => {
		setIsMenuOpen((prev) => !prev);
	}, []);

	const logout = useCallback(() => {
		authService.logout();
	}, []);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (!isMenuOpen) return;
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		},
		[isMenuOpen],
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className={classes["root"]} ref={menuRef}>
			<Bars3Icon className={classes["bars-icon"]} onClick={toggleMenu} />

			{isMenuOpen && (
				<div className={classes["menu"]}>
					<Link to={modules.pages.Home.props.path} className={classes["link"]} onClick={toggleMenu}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.component.header.home)}
						</Typography>
					</Link>

					<Link to={modules.pages.Subscribe.props.path} className={classes["link"]} onClick={toggleMenu}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.component.header.subscribe)}
						</Typography>
					</Link>

					<Link to={modules.pages.Contact.props.path} className={classes["link"]} onClick={toggleMenu}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.component.header.contact)}
						</Typography>
					</Link>

					{!props.user && (
						<div className={classes["not-connected-header-container"]}>
							<NotConnectedHeader className={classes["not-connected-header"]} />
						</div>
					)}

					{props.user && (
						<div className={classes["connected-header"]}>
							<Link to={modules.pages.Profile.props.path} className={classes["link"]} onClick={toggleMenu}>
								<Typography type="span" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.header.personnal_informations)}
								</Typography>
							</Link>

							<Link to={modules.pages.MyProjects.props.path} className={classes["link"]} onClick={toggleMenu}>
								<Typography type="span" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.header.my_projects)}
								</Typography>
							</Link>

							<HasRules requiredRules={{ AND: { access__admin_dashboard: true } }}>
								<Link to={modules.pages.AdminDashboard.props.path} className={classes["link"]}>
									<Typography type="span" size="medium" weight="medium" className={classes["whitespace-wrap"]}>
										{I18n.trslt(I18n.asset.component.header.all_projects)}
									</Typography>
								</Link>
							</HasRules>

							<div onClick={logout} className={classes["link"]}>
								<Typography type="span" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.header.logout)}
								</Typography>
								<ArrowLeftEndOnRectangleIcon className={classes["logout-icon"]} />
							</div>

							<Button
								variant={EButtonVariant.CONTAINED}
								onClick={() => navigate(modules.pages.SubmitProject.props.path)}
								color={EButtonColor.PRIMARY}
								size={EButtonSize.MEDIUM}
								fullwidth>
								<Typography type="span" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.header.submit_project)}
								</Typography>
							</Button>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
